// useGrammarTest.ts
import { useEffect, useState } from "react";
import { useAuth } from "../component/context/AuthContext";
import { dispatch, useSelector } from "../component/redux/store";
import { getVocabByUserId } from "../component/redux/action/vocab";
import authService from "../component/service/auth";


const useGrammarTest = () => {
  const state = useAuth();
  const user = state.user;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalResult, setModalResult] = useState(false);
  const [words, setWords] = useState<string[]>([]);
  const [shuffledWords, setShuffledWords] = useState<string[][]>([]);
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isTestComplete, setIsTestComplete] = useState(false);
  const [startTime, setStartTime] = useState<number | null>(null);
  const testDuration = 300000;
  const { vocabList } = useSelector((state) => state.vocab);
  const [correctCount, setCorrectCount] = useState<number>(0);
  useEffect(() => {
    if (user) {
      dispatch(getVocabByUserId({ userId: user._id, pageSize: 12 }));
    }
  }, [user]);

  useEffect(() => {
    const shuffledWordArray = vocabList.map((word) => word.name).sort(() => 0.5 - Math.random());
    setWords(shuffledWordArray);
    const shuffledLettersArray = shuffledWordArray.map((word) =>
      word.split("").sort(() => 0.5 - Math.random())
    );
    setShuffledWords(shuffledLettersArray);
    setUserAnswers(Array(shuffledWordArray.length).fill(""));
    setStartTime(Date.now());
  }, [vocabList]);

  useEffect(() => {
    setIsModalVisible(true);
  }, []);

  const handleOkData = () => {
    setIsModalVisible(false);
    setIsTestComplete(true);
  };

  const handleInputChange = (value: string) => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestionIndex] = value;
    setUserAnswers(newUserAnswers);
  };

  const handleNext = () => {
    if (currentQuestionIndex < words.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setIsTestComplete(true);
      setModalResult(true);
      countCorrectAnswers();
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const getMeaningByWord = (word: string) => {
    const foundWord = vocabList.find((vocab) => vocab.name === word);
    return foundWord ? foundWord.meaning : "N/A";
  };
  const getWordformByWord = (word: string) => {
    const foundWord = vocabList.find((vocab) => vocab.name === word);
    return foundWord && foundWord.category.length > 0
        ? foundWord.category[0].name
        : "N/A";
}

  const getMaskedWord = (originalWord: string, input: string) => {
    let maskedWord = originalWord.split("");
    let inputArray = input.split("");

    inputArray.forEach((char) => {
      const index = maskedWord.indexOf(char);
      if (index !== -1) {
        maskedWord[index] = "_";
      }
    });

    return shuffleArray(maskedWord).join(" ");
  };

  const shuffleArray = (array: string[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap
    }
    return array;
  };
  const countCorrectAnswers = async () => {
    let correctCount = 0;
    userAnswers.forEach((answer, index) => {
      if (answer.trim().toLowerCase() === words[index].toLowerCase()) {
        correctCount++;
      }
    });
    setCorrectCount(correctCount)
    if (correctCount >= 8) {
      try {
        if(user){
          const response = await authService.putTest({ userId: user._id });
          console.log('Test updated successfully:', response.data);
        }
      } catch (error) {
      }
    }
  };
  return {
    isModalVisible,
    setIsModalVisible,
    modalResult,
    setModalResult,
    words,
    shuffledWords,
    userAnswers,
    setUserAnswers,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    isTestComplete,
    setIsTestComplete,
    startTime,
    testDuration,
    getMeaningByWord,
    handleOkData,
    handleInputChange,
    handleNext,
    handleBack,
    getMaskedWord,
    countCorrectAnswers,
    correctCount,
    getWordformByWord
  };
};

export default useGrammarTest;
