import { Card, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined, CloseOutlined } from "@ant-design/icons";

import { useNavigate, useParams,  } from "react-router-dom";
import { dispatch } from "../../redux/store";
import { openNotification } from "../../global/item/Notification/Notification";
import Loading from "../../global/item/Loading/loading";
import ButtonBottom from "../../global/item/ButtonComponent/ButtonBottom";
import useDecodeToken from "../../../hook/tokenDecode";
import { createTopic, putTopic } from "../../redux/action/topic";
import { useTopic } from "../../../hook/topic/useTopic";

type Prop = {
  isEdit?: boolean;
};
function TopicForm({ isEdit = false }: Prop) {
  const { t } = useTranslation();
  const [uploadedImage, setUploadedImage] = useState<Blob | string | null>(
    null
    
  );
  const { idTopic } = useParams();

  const { topicDetail } = useTopic({ type: 'topic', idTopic: idTopic });

  const { id } = useDecodeToken();
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setUploadedImage(null);
    navigate(-1);  
  };

  const handleFileChange = (info: any) => {
    const file = info.file.originFileObj;
    if (file instanceof Blob) {
      setUploadedImage(file); 
    }
  };

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const values = await form.validateFields();
      const formData = new FormData();
  
      formData.append("name", values.name || "");
  
      if (id) {
        formData.append("userId", id);
      }

      if (uploadedImage) {
        formData.append("image", uploadedImage);
      }
      let resultAction;

      if(isEdit && idTopic){
        resultAction = await dispatch(putTopic({ idTopic:idTopic, formData }));
        setLoading(false)
      }else {
        resultAction = await dispatch(createTopic(formData));
        setLoading(false)

      }
      if (
        resultAction?.type === "wordform/updateWordform/fulfilled" ||
        createTopic.fulfilled.match(resultAction)
      ) {
        openNotification({
          type: "success",
          message: t("success"),
          description: isEdit ? t("successUpdate") : t("successCreate"),
        });
        navigate(-1);
      } else if (
        resultAction?.type === "wordform/updateWordform/rejected" ||
        createTopic.rejected.match(resultAction)
      ) {
        openNotification({
          type: "error",
          message: t("error"),
          description: t("errorCreate"),
        });
      }
    } catch (error) {
      // Handle validation or unexpected errors
      openNotification({
        type: "error",
        message: t("error"),
        description: t("errorCreate"),
      });
    } finally {
      
    }
  };
  

  const handleCloseImg = () => {
    setUploadedImage(null);
  };
  useEffect(() => {
    if (isEdit && topicDetail) {
      form.setFieldsValue({
        name: topicDetail.name || "",
      });

      
      if (typeof topicDetail.image === "string") {
        setUploadedImage(topicDetail.image); 
      } else {
        setUploadedImage(null); 
      }
    }
  }, [isEdit, topicDetail, form]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={
            isEdit
              ? {
                  name: topicDetail?.name,
                }
              : {}
          }
          autoComplete="off"
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Card className="layoutItem">
            <h3 style={{ color: `var(--primary-color)`, marginBottom: 8 }}>
              {t("basic_information")}
            </h3>
            <Row gutter={{ xs: 12, xl: 20 }}>
              <Col xl={24} xs={24}>
              <Form.Item
              label={t("topic")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("please_input_your_data"),
                },
              ]}
            >
              <Input placeholder={t("enter_data_name")} />
            </Form.Item>
              </Col>
              <Col xl={24} xs={24}>
                {uploadedImage ? (
                  <Col xl={24}>
                    <div>
                      <CloseOutlined
                        style={{
                          position: "absolute",
                          right: 24,
                          top: 12,
                          zIndex: 999,
                          fontSize: 16,
                        }}
                        className="iconCustom"
                        onClick={handleCloseImg}
                      />
                      <img
                         src={
                          uploadedImage instanceof Blob
                            ? URL.createObjectURL(uploadedImage)
                            : uploadedImage
                        }
                        alt={t("uploaded_image")}
                        style={{
                          border: `var(--border-line)`,
                          borderRadius: 10,
                          maxWidth: "100%",
                          padding: 10,
                          background: "rgba(0, 0, 0, 0.02)",
                          position: "relative",
                        }}
                        loading="lazy"
                      />
                    </div>
                  </Col>
                ) : (
                  <Form.Item name="image" label={t("image")}>
                    <Dragger
                      name="file"
                      multiple={false}
                      showUploadList={false}
                      onChange={handleFileChange}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">{t("upload_text")}</p>
                      <p className="ant-upload-hint">{t("upload_hint")}</p>
                    </Dragger>
                  </Form.Item>
                )}
              </Col>
             
            </Row>
          </Card>

       
          <ButtonBottom
            onCancelClick={handleCancel}
            btnCancel={t("cancel")}
            btnContinue={t("continue")}
          />
        </Form>
      )}
    </div>
  );
}

export default TopicForm;
