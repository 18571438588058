import { AxiosResponse } from "axios";
import { getAsync, postAsync, putAsync } from "../client";

class WordformService {
  async get(params: any): Promise<AxiosResponse> {
    try {
      const response = await getAsync('/category', {
        ...params,
        isPublish: true
      });
      return response;
    } catch (error) {
      throw error; 
    }
  }

  async post(formData: FormData): Promise<AxiosResponse> {
    return await postAsync('/category', formData);
  }

  async getById(wordformId: string, userId: string): Promise<AxiosResponse> {
    try {
      const response = await getAsync(`/category/${wordformId}?userId=${userId}`);
 
      return response;
    } catch (error) {
      throw error;
    }
  }
  
  async put(id: string,formData: FormData): Promise<AxiosResponse> {
    try {
      const response = await putAsync(`/category/${id}`, formData);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

const wordformServiceInstance = new WordformService();

export default wordformServiceInstance;
