import React from "react";
import TitleComponent from "../../global/Custom/TitleComponent/TitleComponent";
import { useTranslation } from "react-i18next";

import TopicForm from "./TopicForm";

function TopicEdit() {
  const { t } = useTranslation();
  return (
    <div>
      <TitleComponent title={t("edit")} isExist={false} />
      <TopicForm isEdit={true}/>
    </div>
  );
}

export default TopicEdit;
