import React, { useEffect, useState } from "react";
import {
  Affix,
  Avatar,
  Button,
  Col,
  Drawer,
  Dropdown,
  Flex,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  Popover,
  Row,
  theme,
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LoginOutlined,
  MenuOutlined,
  CloseOutlined,
  InboxOutlined,
  AudioOutlined
} from "@ant-design/icons";
import StickyBox from "react-sticky-box";
import SliderApp from "./Slider/Slider";
import { Outlet } from "react-router-dom";

import { useTranslation } from "react-i18next";
import UseRespon from "../../../hook/useResponsive";

import Translation from "../item/Translation/Translation";
import { useAuth } from "../../context/AuthContext";
import LottieAvatar from "../Custom/Avatar/Avatar";
import AvatarAdmin from "../Custom/AvatarAdmin/AvatarAdmin";
import Dragger from "antd/es/upload/Dragger";
import Loading from "../item/Loading/loading";
import { dispatch } from "../../redux/store";
import { openNotification } from "../item/Notification/Notification";

import { sendEmail } from "../../redux/action/global";
import useSpeechSynthesis from "../../context/speech";
const { Header, Sider, Content } = Layout;

const LayoutApp = () => {
  const { isMobile } = UseRespon();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [hasDisplayedTest, setHasDisplayedTest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const state = useAuth()
const user = state.user

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/sign-in";
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [open, setOpen] = useState(false);
  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);
  const { voices, selectVoice,  } = useSpeechSynthesis();
 
  const handleVoiceSelection = (voice: SpeechSynthesisVoice) => {
    selectVoice(voice); 
   
  };
  useEffect(() => {
    if (user?.mustTakeTest === true && !hasDisplayedTest) {
      setHasDisplayedTest(true); 
    }
  }, [user, hasDisplayedTest]);
  const englishVoices = voices.filter(voice => voice.lang.startsWith('en'));

  const items = englishVoices.map(voice => ({
    key: voice.name,
    label: `${voice.name} (${voice.lang})`,
    onClick: () => handleVoiceSelection(voice),
  }));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const formData = new FormData();

      formData.append("message", values.problem);
      if (user) {
        formData.append("userEmail", user.email);
      }
      if (uploadedImage) {
        formData.append("image", uploadedImage);
      }
      const resultAction = await dispatch(sendEmail(formData));
      if (sendEmail.fulfilled.match(resultAction)) {
        openNotification({
          type: "success",
          message: t("success"),
          description: t("successEmail"),
        });
        setIsModalOpen(false);
      } else {
        // Handle errors here
        openNotification({
          type: "error",
          message: t("error"),
          description: t("errorEmail"),
        });
      }
    } catch (error) {
      // Handle validation or unexpected errors
      openNotification({
        type: "error",
        message: t("error"),
        description: t("errorCreate"),
      });
    } finally {
      setLoading(false);
    }
  };
  const [uploadedImage, setUploadedImage] = useState<Blob | null>(null);
  const handleCloseImg = () => {
    setUploadedImage(null);
  };
  const content = (
    <div>
      <div style={{}}>
        <Menu>
          <Menu.Item key="1">
            <a href="/profile">{t("profile")}</a>
          </Menu.Item>
          <Menu.Item key="2">
            <a href="/Schedule">{t("schedule")}</a>
          </Menu.Item>
          <Menu.Item key="3">{t("history")}</Menu.Item>
        </Menu>
      </div>
      <div style={{ padding: 20, borderTop: "1px dashed  #ccc" }}>
        <Button
          type="primary"
          danger
          style={{ width: "100%" }}
          onClick={handleLogout}
        >
          <LoginOutlined />
          {t("logout")}
        </Button>
      </div>
    </div>
  );
  const customTitle = (
    <div style={{ borderBottom: "1px dashed  #ccc", padding: 20 }}>
      <h3 style={{ fontWeight: 500 }}>{user?.username}</h3>
      <p style={{ color: "#b6b6b6", margin: 1, fontWeight: 400 }}>{user?.email}</p>
    </div>
  );
  const handleFileChange = (info: any) => {
    const file = info.file.originFileObj;
    if (file instanceof Blob) {
      setUploadedImage(file); // Store the Blob directly
    }
  };
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Layout style={{ minHeight: "100vh" }}>
          {!isMobile && (
            
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              width="16%"
              style={{
                background: "#fff",
                borderInlineEnd: `var(--border-line)`,
              }}
            >
              <StickyBox offsetTop={10} style={{ width: "100%" }}>
                <div
                  className="demo-logo-vertical"
                  style={{
                    height: "50px",
                    borderRadius: "10px",
                    margin: "10px 4px",
                  }}
                >
              
                  {collapsed ? (
                    <Flex justify="center" style={{height:"100%"}}>
                      <h2
                        style={{
                          color: `var(--primary-color)`,
                          fontFamily: "Playwrite CU, sans-serif",
                          margin:0
                        }}
                      >
                        E
                      </h2>
                    </Flex>
                  ) : (
                    <Flex justify="center" align="center" style={{height:"100%"}}>
                      <h2
                        style={{
                          color: `var(--primary-color)`,
                          fontFamily: "Playwrite CU, sans-serif",
                        }}
                      >
                        E<span style={{ color: "#000" }}>learning</span>
                      </h2>
                    </Flex>
                    
                  )}
                </div>
                <SliderApp
                  
                />
                {collapsed ? null : (
                  <div
                    style={{
                      position: "fixed",
                      bottom: 20,
                      left: "4%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <AvatarAdmin size={80} />
                    <h3 style={{ fontWeight: 500, textAlign: "center" }}>
                    {t("admin")}
                    </h3>
                    <p
                      style={{
                        color: "#b6b6b6",
                        margin: 1,
                        fontWeight: 400,
                        textAlign: "center",
                      }}
                    >
                      Phạm Quang Vinh
                    </p>
                    <Button
                      type="primary"
                      style={{ marginTop: 10 }}
                      onClick={showModal}
                    >
                      {t("contact")}
                    </Button>
                  </div>
                )}
              </StickyBox>
            </Sider>
          )}
          <Layout>
            <Affix offsetTop={0}>
              <Header
                style={{
                  padding: 0,
                  background: colorBgContainer,
                  paddingInline: isMobile ? 16 : 25,
                  height: 64,
                  borderBottom: `var(--border-line)`,
                }}
              >
                <Flex
                  justify="space-between"
                  align="center"
                  style={{ height: "100%" }}
                >
                  <Flex>
                    {isMobile ? (
                      <Button
                        type="text"
                        icon={<MenuOutlined />}
                        onClick={showDrawer}
                      />
                    ) : (
                      <Button
                        type="text"
                        icon={
                          collapsed ? (
                            <MenuUnfoldOutlined />
                          ) : (
                            <MenuFoldOutlined />
                          )
                        }
                        onClick={() => setCollapsed(!collapsed)}
                        className="iconCustom"
                      />
                    )}
                  </Flex>
                 
                  <Flex align="center" gap={isMobile ? 4 : 14}>
                    <Translation />

                    <Dropdown
                     overlay={<Menu items={items} style={{
                      maxHeight: '200px', 
                      overflowY: 'auto',  
                    }}/>}
                    >
                      <AudioOutlined  className="iconCustom"/>
                    </Dropdown>
                   
                    <Popover content={content} title={customTitle}>
                      <Avatar
                        size={39}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        <LottieAvatar size={39} />
                      </Avatar>
                    </Popover>
                  </Flex>
                </Flex>
              </Header>
            </Affix>

            <Content
              style={{
                padding: isMobile ? "0px 16px 24px 16px" : "0px 25px 24px 25px",
                minHeight: "90vh",
               
                background: "#fff",
             
              }}
            >
              <Outlet />
            </Content>
          </Layout>

          <Drawer
            title="Quang Vinh Elearning"
            onClose={onClose}
            open={open}
            placement={"left"}
            size="large"
            width={"72%"}
            className="drawerCustom"
          >
             <SliderApp onItemClick={onClose} />
            <div
              style={{
                position: "fixed",
                bottom: 20,
                left: "19%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <AvatarAdmin size={80} />
              <h3 style={{ fontWeight: 500, textAlign: "center" }}>{t("admin")}</h3>
              <p
                style={{
                  color: "#b6b6b6",
                  margin: 1,
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                Phạm Quang Vinh
              </p>
              <Button
                type="primary"
                style={{ marginTop: 10 }}
                onClick={showModal}
              >
                Contact me
              </Button>
            </div>
          </Drawer>
          <Modal
            title={t("contact")}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            centered
          >
            <Form
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              requiredMark={false}
              onFinish={handleSubmit}
            >
              <Row gutter={{ xs: 12, xl: 20 }}>
                <Col xl={24} xs={24}>
                  <Form.Item
                    label={t("problem")}
                    name="problem"
                    rules={[
                      { required: true, message: t("please_input_your_data") },
                    ]}
                  >
                    <Input placeholder={t("enterProblem")} />
                  </Form.Item>
                </Col>
                <Col xl={24} xs={24}>
                  {uploadedImage ? (
                    <Col xl={24}>
                      <div>
                        <CloseOutlined
                          style={{
                            position: "absolute",
                            right: 24,
                            top: 12,
                            zIndex: 999,
                            fontSize: 16,
                          }}
                          className="iconCustom"
                          onClick={handleCloseImg}
                        />
                        <img
                          src={URL.createObjectURL(uploadedImage)}
                          alt={t("uploaded_image")}
                          style={{
                            border: `var(--border-line)`,
                            borderRadius: 10,
                            maxWidth: "100%",
                            padding: 10,
                            background: "rgba(0, 0, 0, 0.02)",
                            position: "relative",
                          }}
                          loading="lazy"
                        />
                      </div>
                    </Col>
                  ) : (
                    <Form.Item name="image" label={t("imageIfAny")}>
                      <Dragger
                        name="file"
                        multiple={false}
                        showUploadList={false}
                        onChange={handleFileChange}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{t("upload_text")}</p>
                        <p className="ant-upload-hint">{t("upload_hint")}</p>
                      </Dragger>
                    </Form.Item>
                  )}
                </Col>
              </Row>

              <div
                style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}
              >
                <Button style={{ padding: "0 20px" }} onClick={handleCancel}>
                  Hủy
                </Button>
                <Button type="primary" onClick={handleSubmit}>
                  Tiếp tục
                </Button>
              </div>
            </Form>
          </Modal>
          </Layout>
      )}
    </div>
  );
};

export default LayoutApp;
