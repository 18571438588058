import React from 'react'
import GlobalAction from '../../screen/Global'
import TitleComponent from '../../global/Custom/TitleComponent/TitleComponent'
import { useTranslation } from 'react-i18next'

export default function TopicList() {
  const {t} = useTranslation()
  return (
    <div>
       <TitleComponent
        title={t("topic") }
        type="primary"
        data={t("add")}
        to="/Topic/create"
        />
      <GlobalAction action={true} isTopic={true}/>
    </div>
  )
}
