import React, { useEffect, useState } from "react";
import { Button, Card, Carousel, Col, Flex, Progress, Row } from "antd";
import {
  LineChart,
  Line,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
} from "recharts";
import animation from "../../../accset/json/hello.json";
import Lottie from "lottie-react";
import { useAuth } from "../../context/AuthContext";
import { dispatch, useSelector } from "../../redux/store";
import { getWordform } from "../../redux/action/grammar/wordform";
import UseRespon from "../../../hook/useResponsive";
import {
  getCountNotRememberByUser,
  getCountRememberByUser,
  getCountVocabByUserId,
  getStatistical,
} from "../../redux/action/statistical";
import { Link } from "react-router-dom";
const AnimatedProgress: React.FC<{ title: string; percent: number }> = ({
  title,
  percent,
}) => {
  const [currentPercent, setCurrentPercent] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = percent;
    const duration = 600;
    const increment = end / (duration / 100);

    const timer = setInterval(() => {
      start += increment;
      if (start >= end) {
        start = end;
        clearInterval(timer);
      }
      setCurrentPercent(Math.round(start));
    }, 100);

    return () => clearInterval(timer);
  }, [percent]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ width: "100px" }}>{title}</span>
      <Progress percent={currentPercent} style={{ flexGrow: 1 }} />
    </div>
  );
};
const CustomTooltip = ({ active, payload }:any) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload; 
    return (
      <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
        <p>Data: {name}</p>
        <p>Value: {value}</p>
      </div>
    );
  }
  return null;
};
const Dashboard = () => {
  const { user } = useAuth();
  const { wordformList } = useSelector((state) => state.wordform);
  const { totalVocab, countVocab, countVocabNotRemember, countVocabRemember } = useSelector((state) => state.statistical);
  
  const isMobile = UseRespon();

  useEffect(() => {
    dispatch(getWordform({}));
  }, []);
  useEffect(() => {
    dispatch(getStatistical(user?._id));
    if (user && user._id) {
      dispatch(getCountVocabByUserId({ userId: user._id }));
      dispatch(getCountRememberByUser(user._id));
      dispatch(getCountNotRememberByUser(user._id));

    }
  }, [user]);
  const lineData = countVocab.map((item) => {
    const [year, month, day] = item.date.split("-");

    return {
      name: `${day}-${month}-${year}`,
      value: item.count,
    };
  });
  const lineDataRemember = countVocabRemember.map((item) => {
    const [year, month, day] = item.date.split("-");

    return {
      name: `${day}-${month}-${year}`,
      value: item.count,
    };
  });
  const lineDataNotRemember = countVocabNotRemember.map((item) => {
    const [year, month, day] = item.date.split("-");

    return {
      name: `${day}-${month}-${year}`,
      value: item.count,
    };
  });
  const pieData = [
    { name: "Tính từ", value: 400 },
    { name: "Danh từ", value: 300 },
    { name: "Động từ", value: 300 },
    { name: "Trạng từ", value: 200 },
  ];

  // Define colors for each group
  const COLORS = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"];

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >
          {`${value} từ`}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Chiếm ${(percent * 100).toFixed(0)}%)`}
        </text>
      </g>
    );
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_: any, index: any) => {
    setActiveIndex(index);
  };

  return (
    <div style={{ padding: "19px 0" }}>
      <Row gutter={[20, 20]}>
        <Col xl={16} xs={24} style={{ height: "100%" }}>
          <Card
            style={{
              background: "linear-gradient(135deg, #e6f3ff 0%, #1677ff 100%)",
              boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "15px",
            }}
          >
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col
                xl={16}
                xs={24}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h2 style={{ margin: 0, color: "#0c3a7b" }}>Welcome back 👋</h2>
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: 17,
                    color: "#0c3a7b",
                    margin: "8px 0",
                  }}
                >
                  {user?.username || "Phạm Quang Vinh"}
                </p>
                <p style={{ color: "#555", fontWeight: 400, maxWidth: 420 }}>
                  Welcome to the world of English learning! Discover effective
                  methods to boost your confidence and unlock new opportunities.
                </p>
                <Link to={"vocab"}>
                <Button
                  type="primary"
                  style={{ marginTop: "24px", width: 100 }}
                >
                  Go now
                </Button>
                </Link>
               
              </Col>
              <Col
                xl={8}
                xs={24}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Lottie
                  animationData={animation}
                  style={{ width: "100%", margin: "0 auto" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={8} xs={24} style={{ height: "100%" }}>
          <Carousel style={{ width: "100%" }}>
            {wordformList.map((wordform, index) => (
              <div key={index} style={{ position: "relative" }}>
                <div
                  style={{
                    backgroundImage: `url(${wordform.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    minHeight: "295px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>

      <div style={{ margin: "20px 0" }}>
        <Row gutter={[20, 20]}>
          <Col xl={8} xs={24}>
            <Card style={{ height: "100%" }}>
              <Row>
                <Col xl={14}>
                  <p style={{ margin: 0, fontWeight: 500 }}>Tổng Từ Vựng</p>
                  <h1 style={{ color: "#1677ff", margin: "6px 0" }}>
                    {totalVocab.totalCount}
                  </h1>
                  <p style={{ color: "green", margin: 0 }}>
                    +2,6% từ tuần trước
                  </p>
                </Col>
                <Col
                  xl={10}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end", // Căn chỉnh về phía cuối
                    width: isMobile ? "80px" : "100%",
                    flex: "1 0 auto",
                  }}
                >
                  <ResponsiveContainer
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    width={isMobile ? 130 : "100%"}
                    height={isMobile ? 100 : 100}
                  >
                    <LineChart data={lineData}>
                    <Tooltip content={<CustomTooltip />} />
                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#1677ff"
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xl={8} xs={24}>
            <Card style={{ height: "100%" }}>
              <Row>
                <Col xl={14}>
                  <p style={{ margin: 0, fontWeight: 500 }}>Đã nhớ</p>
                  <h1 style={{ color: "#1677ff", margin: "6px 0" }}>
                    {totalVocab.rememberedCount}
                  </h1>
                  <p style={{ color: "green", margin: 0 }}>
                    +2,6% từ tuần trước
                  </p>
                </Col>
                <Col
                  xl={10}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end", // Căn chỉnh về phía cuối
                    width: isMobile ? "80px" : "100%",
                    flex: "1 0 auto",
                  }}
                >
                  <ResponsiveContainer
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    width={isMobile ? 130 : "100%"}
                    height={isMobile ? 100 : 100}
                  >
                    <LineChart data={lineDataRemember}>
                    <Tooltip content={<CustomTooltip />} />

                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#1677ff"
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xl={8} xs={24}>
            <Card style={{ height: "100%" }}>
              <Row>
                <Col xl={14}>
                  <p style={{ margin: 0, fontWeight: 500 }}>Chưa nhớ</p>
                  <h1 style={{ color: "#1677ff", margin: "6px 0" }}>
                    {totalVocab.notRememberedCount}
                  </h1>
                  <p style={{ color: "green", margin: 0 }}>
                    +2,6% từ tuần trước
                  </p>
                </Col>
                <Col
                  xl={10}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end", // Căn chỉnh về phía cuối
                    width: isMobile ? "80px" : "100%",
                    flex: "1 0 auto",
                  }}
                >
                  <ResponsiveContainer
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    width={isMobile ? 130 : "100%"}
                    height={isMobile ? 100 : 100}
                  >
                    <LineChart data={lineDataNotRemember}>
                    <Tooltip content={<CustomTooltip />} />

                      <Line
                        type="monotone"
                        dataKey="value"
                        stroke="#1677ff"
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>

      <div>
        <Row gutter={[20, 20]}>
          <Col xl={8} xs={24}>
            <Card>
              <ResponsiveContainer
                width="100%"
                height={isMobile ? 200 : "100%"}
              >
                <PieChart>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    innerRadius={isMobile ? 30 : 60}
                    outerRadius={isMobile ? 50 : 80}
                    fill="#8884d8"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Card>
          </Col>
          <Col xl={16} xs={24}>
            <Card>
              <Flex vertical gap={22}>
                <AnimatedProgress title="Task 1" percent={30} />
                <AnimatedProgress title="Task 2" percent={50} />
                <AnimatedProgress title="Task 3" percent={70} />
                <AnimatedProgress title="Task 4" percent={10} />
                <AnimatedProgress title="Task 5" percent={100} />
              </Flex>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
