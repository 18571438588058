import { useLocation } from "react-router-dom";
import { useWordform } from "../../../../hook/wordform/useWordform";
import TitleComponent from "../../../global/Custom/TitleComponent/TitleComponent";
import WordformVocab from "./WordformVocab";
import { CgNotes } from "react-icons/cg";
import { FiCommand } from "react-icons/fi";
import { Flex, Tabs } from "antd";
import { useAuth } from "../../../context/AuthContext";

export default function WordformDetail() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idWordform = queryParams.get("id") || undefined;
  const state = useAuth();
  const user = state.user;
  const { wordformDetail, vocabcount } = useWordform({
    type: "wordform",
    idWordform: idWordform,
    userId: user?._id,
  });

  const items = [
    {
      key: "1",
      label: (
        <Flex align="center" gap={8}>
          <FiCommand size={14} />
          Từ vựng
        </Flex>
      ),
      children: <WordformVocab categoryId={idWordform} />,
    },
    {
      key: "2",
      label: (
        <Flex align="center" gap={8}>
          <CgNotes size={14} />
          Lý thuyết
        </Flex>
      ),
      children: (
        <p
          style={{ fontSize: 15 }}
          dangerouslySetInnerHTML={{ __html: wordformDetail.desc }}
        ></p>
      ),
    },
  ];
  return (
    <div>
      <TitleComponent
        title={wordformDetail.name}
        type="primary"
        icon={false}
        data={vocabcount}
        textSub=" từ vựng"
      />
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
}
