import { AxiosResponse } from "axios";
import { deleteAsync, getAsync, postAsync, putAsync } from "./client";

class TopicService {
  async get(params: any): Promise<AxiosResponse> {
    try {
      const response = await getAsync('/topic', {
        ...params,
        isPublish: true
      });
      return response;
    } catch (error) {
      throw error; 
    }
  }

  async getById(topicId: string): Promise<AxiosResponse> {
    try {
      const response = await getAsync(`/topic/${topicId}`);
      return response;
    } catch (error) {
      throw error; 
    }
  }

  async post(formData: FormData): Promise<AxiosResponse> {
    try {
      const response = await postAsync('/topic', formData);
      return response;
    } catch (error) {
      throw error; 
    }
  }
  async getByUser(userId: string): Promise<AxiosResponse> {
    try {
      const response = await getAsync(`/topic/user/${userId}`);
      return response;
    } catch (error) {
      throw error; 
    }
  }
  async put(idTopic: string,formData: FormData): Promise<AxiosResponse> {
    try {
      const response = await putAsync(`/topic/${idTopic}`, formData);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async delete(_id: string): Promise<AxiosResponse> {
    return await deleteAsync(`/topic/${_id}`);
  }
}

// Create an instance of TopicService
const topicServiceInstance = new TopicService();

export default topicServiceInstance;
