import GlobalAction from '../../../screen/Global'
import TitleComponent from '../../../global/Custom/TitleComponent/TitleComponent'
import { useTranslation } from 'react-i18next'

export default function WordformList() {
  const {t} = useTranslation()
  return (
    <div>
      <TitleComponent
        title={t("wordform")}
        isExist={false}
        />
      <GlobalAction action={false} isTopic={false}/>
      
    </div>
  )
}
