import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Dropdown, Flex, Input, Modal, Row } from "antd";
import {
  SearchOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { CiCalendarDate, CiDiscount1 } from "react-icons/ci";
import { PiCirclesThreePlusThin } from "react-icons/pi";
import { useTopic } from "../../hook/topic/useTopic";
import { useAuth } from "../context/AuthContext";
import UseRespon from "../../hook/useResponsive";
import { useWordform } from "../../hook/wordform/useWordform";
import imageUI from "../../accset/image/backgroud.svg";
import { Link } from "react-router-dom";
import moment from "moment";
import { dispatch } from "../redux/store";
import { deleteTopic, } from "../redux/action/topic";
type Props = {
  action: boolean;
  isTopic: boolean;
};
const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

function GlobalAction({ action, isTopic = true }: Props) {
  const { t } = useTranslation();
  const state = useAuth();
  const user = state.user;
  const { topicList } = useTopic({ type: "user", userId: user?._id });
  const { wordformList } = useWordform({ type: "list", userId: user?._id });

  let data: any;
  if (action === true) {
    data = topicList;
  } else {
    data = wordformList;
  }

  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = UseRespon().isMobile ? 2 : 8;
  const [isFocused, setIsFocused] = useState(false);
  const debouncedValue = useDebounce(value, 300);
  const [idSelected, setIdselected] = useState("");

  const filteredItems = useMemo(() => {
    return Array.isArray(data)
      ? data.filter((item) =>
          item.name.toLowerCase().includes(debouncedValue.toLowerCase())
        )
      : [];
  }, [data, debouncedValue]);

  const currentItems = useMemo(
    () =>
      filteredItems.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
      ),
    [filteredItems, currentPage, itemsPerPage]
  );

  const handleChange = useCallback((e: any) => {
    setValue(e.target.value);
    setCurrentPage(0); // Reset to the first page when the search value changes
  }, []);

  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);

  const handleNextPage = useCallback(() => {
    if ((currentPage + 1) * itemsPerPage < filteredItems.length) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, filteredItems.length, itemsPerPage]);

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);
  const handleIconClick = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevents the Link navigation
    e.stopPropagation(); // Stops event bubbling
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (id:any) => {
    setIsModalOpen(true);
    setIdselected(id)
  };
  const handleOk = () => {
    setIsModalOpen(false);

    dispatch(deleteTopic(idSelected))
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <Input
          value={value}
          onChange={handleChange}
          placeholder={t("searchItem")}
          prefix={
            <SearchOutlined
              style={{
                color: isFocused ? "blue" : "black",
              }}
            />
          }
          style={{
            width: UseRespon().isMobile ? "50%" : "24%",
            boxShadow: "none",
            border: "none",
            borderRadius: 0,
            borderBottom: "1px solid #d9d9d9",
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <div style={{ display: "flex", gap: 10 }}>
          <Button
            className="buttonPointer"
            icon={<ArrowLeftOutlined />}
            onClick={handlePreviousPage}
            disabled={currentPage === 0}
          />
          <Button
            className="buttonPointer"
            icon={<ArrowRightOutlined />}
            onClick={handleNextPage}
            disabled={(currentPage + 1) * itemsPerPage >= filteredItems.length}
          />
        </div>
      </div>
      <Row gutter={[20, 20]}>
        <TransitionGroup component={null}>
        {currentItems.map((item) => {
  const dropdownItems = [
    {
      key: "1",
      label: (
        <Link to={`/Topic/Edit/${item._id}`}>
          <p>Chỉnh sửa</p>
        </Link>
      ),
    },
    {
      key: "2",
      label: <p onClick={() => showModal(item._id)}>Xóa</p>,
    },
  ];

  return (
    <CSSTransition key={item._id} timeout={500} classNames="fade">
      <Col xl={6} xs={24}>
        <Link
          to={
            action === true
              ? `/Topic/Detail?id=${item._id}`
              : `/Grammar/Wordform/Detail?id=${item._id}`
          }
        >
          <Card className="card-item cardItem">
            <div
              className="background-image"
              style={{
                backgroundImage: `url(${item.image || imageUI})`,
              }}
            ></div>
            <div className="content-item">
              <p
                style={{
                  fontWeight: 700,
                  color: `var(--primary-color)`,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.name}
              </p>

              <Flex justify="space-between" align="center">
                <div>
                  <Flex align="center" gap={8}>
                    <CiDiscount1 size={18} />
                    <p style={{ fontWeight: 400 }}>{item.vocabCount} từ</p>
                  </Flex>
                  <Flex align="center" gap={8}>
                    <CiCalendarDate size={18} />
                    <p style={{ fontWeight: 400 }}>
                      {moment(item.createDate).format("DD-MM-YYYY")}
                    </p>
                  </Flex>
                </div>
                {isTopic && (
                  <div
                    onClick={handleIconClick}
                    style={{ cursor: "pointer" }}
                    role="button"
                    aria-label="More options"
                  >
                    <Dropdown
                      menu={{
                        items: dropdownItems, // Use the dynamically created `items` array
                      }}
                    >
                      <PiCirclesThreePlusThin size={18} />
                    </Dropdown>
                  </div>
                )}
              </Flex>
            </div>
          </Card>
        </Link>
      </Col>
    </CSSTransition>
  );
})}

        </TransitionGroup>
      </Row>
      <Modal  open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered>
        <h2>Xóa chủ đề</h2>
        <p style={{paddingBottom:10}}>Bạn có muốn xóa chủ đề này không?</p>
      </Modal>
    </div>
  );
}

export default GlobalAction;
